import TechSupportApi from "../api/techSupport.js";
import { Button } from "../ui/components/Button";

export class SendBtn extends Button {
    init() {
        super.init();

        this.includeComponents({
            'addressInput': 'document@#address',
            'bioInput': 'document@#bio',
            'attachmentInput': 'document@#attachment',
            'typeInput': 'document@#type',
            'invoiceInput': 'document@#invoice',
            'errorBagAddress': 'document@.invalid-feedback-address',
            'errorBagBio': 'document@.invalid-feedback-bio',
            'errorBagAttachment': 'document@.invalid-feedback-attachment',
            'bioTip': 'document@.bio-tip',
        });

        this.on('click', this.onClick.bind(this));
    }

    onClick(e) {
        e.preventDefault();
        this.disable();

        if (!this.validate()) {
            return this;
        }

        TechSupportApi.submit({
            address: this.addressInput.element.value,
            bio: this.bioInput.element.value,
            attachment: this.attachmentInput.element.files[0],
            type: this.typeInput.element.value,
            invoice: this.invoiceInput.element.value
        }).then((response) => {
            if (response.status == 'sent') {
                window.location.reload();
            }
        }).catch((error) => {
            console.log(this.errorBagAddress)
            if (error.address) {
                this.addressInput.element.className += ' is-invalid';
                this.errorBagAddress.element.innerText = error.address;
            }

            if (error.bio) {
                this.bioInput.element.className += ' is-invalid';
                this.bioTip.element.className += ' d-none';
                this.errorBagBio.element.innerText = error.bio;
            }

            if (error.attachment) {
                this.attachmentInput.element.className += ' is-invalid';
                this.errorBagAttachment.element.innerText = error.attachment;
            }

            this.enable();
        });

        return this;
    }

    validate() {
        let valid = true;
        if (this.addressInput.element.value == '') {

            this.addressInput.element.classList.add('is-invalid');
            this.errorBagAddress.element.innerText = 'Поле «Отправитель» обязательно для заполнения';
            valid = false;
        } else {
            let check = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu.test(this.addressInput.element.value)
            if (!check) {
                this.addressInput.element.classList.add('is-invalid');
                this.errorBagAddress.element.innerText = 'Поле Отправитель должно быть действительным электронным адресом';
                valid = false;
            } else {
                this.addressInput.element.classList.remove('is-invalid');
                this.errorBagAddress.element.innerText = '';
            }
        }

        if (this.bioInput.element.value.length < 30) {
            this.bioInput.element.classList.add('is-invalid');
            this.bioTip.element.classList.add('d-none');
            this.errorBagBio.element.innerText = 'Количество символов в поле «Сообщение» должно быть не менее 30';
            valid = false;
        } else {
            this.bioInput.element.classList.remove('is-invalid');
            this.bioTip.element.classList.remove('d-none');
            this.errorBagBio.element.innerText = '';
        }

        if (!valid) {
            this.enable();
        }

        return valid;
    }
}
